/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

a {
  text-decoration: none;
}

.middle {
  min-height: calc(100vh - 100px);
}

.body, .section, .rendered-entry, .left, .right {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}

.section {
  flex: 1;
  max-height: calc(100vh - 101px);
  overflow: scroll;
  p, .restrict {
    max-width: 1000px;
    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-height: 560px) {
  .section {
    max-height: calc(100vh);
  }
}

.left {
  width: 100%;
}

button.fade {
  opacity: 0.2;
  transition: opacity .25s ease-in-out !important;
  -moz-transition: opacity .25s ease-in-out !important;
  -webkit-transition: opacity .25s ease-in-out !important;
  &:hover {
    opacity: 1;
  }
}

.sidenav {
  float: right;
  max-width: 400px;
  //h1, h2, h3, h4, h5, h6 {
  //  text-align: right;
  //  margin-right: 20px;
  //}
  //.mat-list-item-content {
  //  justify-content: right;
  //}
}

@import "~prismjs/plugins/toolbar/prism-toolbar.css";

/*
 Solarized Color Schemes originally by Ethan Schoonover
 http://ethanschoonover.com/solarized

 Ported for PrismJS by Michael Deeb
 Website: https://michaeljdeeb.com
 Twitter Handle: https://twitter.com/michaeljdeeb)
*/

/*
SOLARIZED HEX
--------- -------
base03    #002b36
base02    #073642
base01    #586e75
base00    #657b83
base0     #839496
base1     #93a1a1
base2     #eee8d5
base3     #fdf6e3
yellow    #b58900
orange    #cb4b16
red       #dc322f
magenta   #d33682
violet    #6c71c4
blue      #268bd2
cyan      #2aa198
green     #859900
*/

code[class*="language-"],
pre[class*="language-"] {
  color: #839496; /* base0 */
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;

  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  overflow: auto;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  background: #073642; /* base02 */
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
  background: #073642; /* base02 */
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #002b36; /* base03 */
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.attr-name,
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #93a1a1; /* base1 */
}

.token.punctuation {
  color: #657b83; /* base00 */
}

.namespace {
  opacity: .7;
}

.token.property,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #d33682; /* magenta */
}

.token.attr-value,
.token.selector,
.token.string,
.token.char,
.token.builtin,
.token.url,
.token.inserted {
  color: #2aa198; /* cyan */
}

.token.entity {
  color: #2aa198; /* cyan */
}

.token.atrule,
.token.keyword {
  color: #859900; /* yellow */
}

.token.tag,
.token.function {
  color: #268bd2; /* blue */
}

.token.regex,
.token.important,
.token.variable {
  color: #cb4b16; /* orange */
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
